/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */



(function($) {

  var src;

  var mode;

  var container;


  function gridIt(){

    container = document.querySelector('.jsmasonry');

    $(container).masonry();

  }

  function gridImg(){

    imagesLoaded('.jsmasonry', function(){

      gridIt();

    });

  }

  function gridVid(){

    imagesLoaded('.jsmasonry', function(){

      gridIt();

      $(".item").fitVids();

    });

  }

  function gridMem(){

    gridImg();

    $(".item").fitVids();

  }

  function stopVid(){

    $('.modal').on('show.bs.modal', function(){

      mode = $(this).find("iframe");

      src = mode.attr('src');

    });

    $('.modal').on('hide.bs.modal', function(){

      mode.attr('src', '');

      mode.attr('src', src);

    })

  };

  function subNavScroll() {
    if (window.matchMedia("(min-width: 992px)").matches) {
      $(window).on('scroll', function(e) {

        if ( $(window).scrollTop() > $('#menu-categories-anchor').offset().top ) {
            $('.menu-categories-container').addClass('fixed-top');
            $('#menu-categories-anchor').height( $('.menu-categories-container').outerHeight() );
        } else {
            $('.menu-categories-container').removeClass('fixed-top');
            $('#menu-categories-anchor').height(0);
        }

        if ( $(window).scrollTop() > $('#brunch-categories-anchor').offset().top ) {
          console.log("fixed-top");
            $('#brunch-menu-scroll').addClass('fixed-top');
            $('#brunch-categories-anchor').height( $('.brunch-categories-container').outerHeight() );
        } else {
            $('.brunch-categories-container').removeClass('fixed-top');
            $('#brunch-categories-anchor').height(0);
        }

      });
    } else if (window.matchMedia("(max-width: 991px)").matches) {
      $(window).on('scroll', function(e) {

        if ( ($(window).scrollTop() > $('#menu-categories-anchor-mobile').offset().top) || ($(window).scrollTop() > $('#brunch-categories-anchor-mobile').offset().top) ) {
            $('.menu-categories-container-mobile').addClass('fixed-top');
            $('#brunch-categories-anchor-mobile').height( $('.menu-categories-container-mobile').outerHeight() );
            $('#menu-categories-anchor-mobile').height( $('.menu-categories-container-mobile').outerHeight() );
        } else {
            $('.menu-categories-container-mobile').removeClass('fixed-top');
            $('#menu-categories-anchor-mobile').height(0);
            $('#brunch-categories-anchor-mobile').height(0);
        }

      });
    }

  };

// Use this variable to set up the common and page specific functions. If you

// rename this variable, you will also need to rename the namespace below.

var Roots = {

  // All pages

  common: {

    init: function() {

      // JavaScript to be fired on all pages

      $(window).load(function() {

        // Load location map for footer on all pages
        var latLng = {lat: 36.173760, lng: -86.785043};

        var map = new google.maps.Map(document.getElementById('map'), {
          disableDefaultUI: true,
          scrollwheel: false,
          center: latLng,
          zoom: 17
        });

        var marker = new google.maps.Marker({
          position: latLng,
          map: map,
          label: {
            color: '#65191b',
            fontWeight: 'bold',
            text: '1004 4th Avenue North'
          }
        });

        // Swap out desktop header image for rmobile.
        if ( $('.Header-image').hasClass('backstretch') ) {
          var $image = $('.Header-image.backstretch');

          if (window.matchMedia("(min-width: 992px)").matches) {
            if( $image.data('bgr') ) {
              $image.css('background-image', 'url(' + $image.data('bgr') + ')');
            }
          } else if (window.matchMedia("(max-width: 991px)").matches) {
            if( $image.data('bgrmobile') ) {
              $image.css('background-image', 'url(' + $image.data('bgrmobile') + ')');
            }
          }
        }

      });

      $('.backstretch').addClass("dim");

      var getUrlParameter = function getUrlParameter(sParam) {
          var sPageURL = window.location.search.substring(1),
              sURLVariables = sPageURL.split('&'),
              sParameterName,
              i;

          for (i = 0; i < sURLVariables.length; i++) {
              sParameterName = sURLVariables[i].split('=');

              if (sParameterName[0] === sParam) {
                  return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
              }
          }
      };

      function BrunchToggle() {
        $('#brunch-toggle').addClass('active');
        $('#menu-toggle').removeClass('active');
        $('#menu').fadeOut('slow');
        $('#brunch').fadeIn('slow');
      }
      function MenuToggle() {
        $('#menu-toggle').addClass('active');
        $('#brunch-toggle').removeClass('active');
        $('#brunch').fadeOut('slow');
        $('#menu').fadeIn('slow');
      }
      $('#brunch-toggle').click(function() {
        console.log("clicky");
        BrunchToggle();
      });
      $('#menu-toggle').click(function() {
        console.log("clicky");
        MenuToggle();
      });

      var menuLink = getUrlParameter('menu');
      if (menuLink == 'main') {
        MenuToggle();
      } else if (menuLink == 'brunch') {
        BrunchToggle();
      } else {

      }



      $('.video-container').fitVids();

      $('body').scrollspy({ target: '#brunch-menu-scroll', offset: 155 });
      $('body').scrollspy({ target: '#food-menu-scroll', offset: 155 });

      $('.menu-categories [id^="menu-"]').on('click', function(e) {
        e.preventDefault();

        var hash = this.hash;


        $('html, body').animate({
          scrollTop: $(hash).offset().top - 70
        }, 1000, function(){

          window.location.hash = hash;

        });
      });

      var $liOptions =  $(".menu-categories-container-mobile .dropdown-menu li");
      var $suppressMouse = false;

      //The function that is listening to the mouse
      $liOptions.mouseenter(function () {
        if (!$suppressMouse) {
          $liOptions.filter('.selected').removeClass('selected');
          $(this).addClass('selected').focus();
        }
      }).mousemove(function () {
          if (!$suppressMouse) {
            $liOptions.filter('.selected').removeClass('selected');
            $(this).addClass('selected').focus();
          }
          $suppressMouse = false;
      });

      // If the li item is clicked display the value inside the button
      $liOptions.click(function () {
        $(this).parent().parent().parent().find('.value').html($(this).text());

        $(this).parent().parent().parent().find('input').val($(this).data('key'));
      });

      //What to do when the keyboard is pressed
      $liOptions.keydown(function (e) {
        e.preventDefault();
        var selected = $liOptions.filter(".selected").removeClass('selected');
        $suppressMouse = true;
      });

      $('[data-toggle="slide-collapse"]').on('click', function() {
        $(this).toggleClass('close-icon');
        $(this).text(function(i, text){
          return text === "More" ? "Close" : "More";
        });

        $navMenuCont = $($(this).data('target'));
        $navMenuCont.animate({'width':'toggle'}, 350);
      });

    }

  },

  // Home page

  home: {

    init: function() {

      // Swap out desktop header image fo rmobile.
      if ( $('.Header').hasClass('backstretch') ) {
        var $image = $('.Header.backstretch');

        if (window.matchMedia("(max-width: 991px)").matches) {
          if( $image.data('bgrmobile') ) {
            $image.css('background-image', 'url(' + $image.data('bgrmobile') + ')');
          }
        }
      }

    }
  },

  page_template_template_menu: {
    init: function() {

      subNavScroll();

    }
  },

  page_template_template_beer: {
    init: function() {

      subNavScroll();

    }
  },

  page_template_template_parties: {

    init: function() {

      $(window).load(function() {
        var $sliderItemTotal = $(".flex-navigation-counter .slider-item-total"),
            $sliderItemCurrent = $(".flex-navigation-counter .slider-item-current"),
            $mobileItemTotal = $(".flexslider-navigation-counter .slider-item-total"),
            $mobileItemCurrent = $(".flexslider-navigation-counter .slider-item-current");

        $('#slider').flexslider({
          animation: "slide",
          controlNav: false,
          customDirectionNav: $(".flex-navigation a"),
          start: function(item){
            slideCounter(item);
          },
          after: function(item){
            slideCounter(item);
          }
        });

        $('#slider-mobile').flexslider({
          animation: "slide",
          controlNav: false,
          customDirectionNav: $(".flexslider-navigation a"),
          start: function(item){
            mobileCounter(item);
          },
          after: function(item){
            mobileCounter(item);
          }
        });

        function slideCounter(item){
          $sliderItemCurrent.text(item.currentSlide + 1);
          $sliderItemTotal.text(item.count);
        }

        function mobileCounter(item){
          $mobileItemCurrent.text(item.currentSlide + 1);
          $mobileItemTotal.text(item.count);
        }
      });

      $('.Parties-content-intro-button a').on('click', function(e) {
        e.preventDefault();

        var hash = this.hash;


        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 1000, function(){

          window.location.hash = hash;

        });

      });

    }

  }

};


// The routing fires all common scripts, followed by the page specific scripts.

// Add additional events for more control over timing e.g. a finalize event

var UTIL = {

  fire: function(func, funcname, args) {

    var namespace = Roots;

    funcname = (funcname === undefined) ? 'init' : funcname;

    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {

      namespace[func][funcname](args);

    }

  },

  loadEvents: function() {

    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {

      UTIL.fire(classnm);

    });

  }

};


$(document).ready(UTIL.loadEvents);




})(jQuery); // Fully reference jQuery after this point.
